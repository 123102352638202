import "../styles/styles.scss";
import "../public/js/vendor/highlight/styles/dark.min.css";

function MyApp({ Component, pageProps }) {
  return (
    <Component {...pageProps} />
  );
}

export default MyApp;
